import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

interface Props {
  children?: React.ReactNode;
}

export const Layout = ({ children }: Props) => (
  <div className="min-h-screen flex flex-col bg-gray-50">
    <Header />
    <main className="grow p-4 prose prose-gray">{children}</main>
    <Footer />
  </div>
);
