import React from "react";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { StaticImage } from "gatsby-plugin-image";
import { Links } from "../../../links";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BoltIcon,
  CheckBadgeIcon,
  GlobeEuropeAfricaIcon,
  HomeModernIcon,
  MicrophoneIcon,
  PaperAirplaneIcon,
  UserGroupIcon,
  VideoCameraIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

type Item = {
  name: string;
  description: string;
  href: string;
  icon?: typeof MicrophoneIcon;
};

const functions: Item[] = [
  {
    name: "Interviews transkribieren",
    description: "Perfekt für Journalist:innen und Studierende",
    href: Links.landing.interview,
    icon: MicrophoneIcon,
  },
  {
    name: "Sitzungen automatisch protokollieren",
    description: "Sauber formatierte Sitzungsprotokolle erzeugen",
    href: Links.landing.meeting,
    icon: UserGroupIcon,
  },
  {
    name: "Sprachnachrichten in Text umwandeln",
    description: "Empfangene Nachrichten lesen statt abhören",
    href: Links.landing.voiceMessage,
    icon: PaperAirplaneIcon,
  },
  {
    name: "Texte auf Mundart diktieren",
    description: "Steigere deine Effizienz im Alltag",
    href: Links.landing.dictate,
    icon: BoltIcon,
  },
  {
    name: "Schweizerdeutsch verstehen lernen",
    description: "Spielerisch Schweizerdeutsch entdecken",
    href: Links.landing.learnSwissGerman,
    icon: GlobeEuropeAfricaIcon,
  },
  {
    name: "Video-Untertitel generieren",
    description: "Auf Mundart gesprochene Videos untertiteln",
    href: Links.landing.videoSubtitles,
    icon: VideoCameraIcon,
  },
  {
    name: "Predigten transkribieren",
    description: "Gottesdienste in Text umwandeln",
    href: Links.landing.sermon,
    icon: HomeModernIcon,
  },
  {
    name: "Rechtschreibhilfe",
    description: "Fehlerfrei schriftlich kommunizieren",
    href: Links.landing.spelling,
    icon: CheckBadgeIcon,
  },
];

const resources: Item[] = [
  {
    name: "Beispiel-Transkriptionen",
    description: "So sehen mit der App erstellte Dokumente aus",
    href: Links.beispiele.index,
  },
  {
    name: "Videoanleitungen",
    description: "Lerne wie du die App effizient bedienst",
    href: Links.videoanleitungen,
  },
  {
    name: "Häufig gestellte Fragen",
    description: "Erfahre mehr zur Schweizerdeutsch Übersetzer App",
    href: Links.faq,
  },
];

const callsToAction: Item[] = [
  //   { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  //   { name: "Contact sales", href: "#", icon: PhoneIcon },
];

const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

// export const Header = () => (
//   <header className="bg-swamp-green-700 text-swamp-green-50 flex items-center gap-x-8">
//     <StaticImage
//       className="m-1 rounded-md"
//       src="../../../images/icon.png"
//       alt="Milky von der Schweizerdeutsch Übersetzer App"
//       height={60}
//       layout="fixed"
//     />
//     <Link className="text-white" href={Links.startseite}>
//       Startseite
//     </Link>
//     <Link className="text-white" href={Links.kontakt}>
//       Kontakt
//     </Link>
//     <Link
//       className="uppercase font-bold font-lexend tracking-widest text-white hover:text-salmon-400 hover:no-underline transition-colors"
//       href={Links.instagram}
//     >
//       <FontAwesomeIcon icon={faInstagram} title="Instagram" />
//     </Link>
//     <Link
//       className="uppercase font-bold font-lexend tracking-widest text-white hover:text-salmon-400 hover:no-underline transition-colors"
//       href={Links.facebook}
//     >
//       <FontAwesomeIcon icon={faFacebook} title="Facebook" />
//     </Link>
//   </header>
// );

type DesktopPopoverProps = {
  title: string;
  items: Item[];
  callToActions: Item[];
  isAlignRight?: boolean;
};

const DesktopPopover = ({
  title,
  items,
  callToActions,
  isAlignRight,
}: DesktopPopoverProps) => (
  <Popover className="relative">
    <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
      {title}
      <ChevronDownIcon
        // className="h-5 w-5 flex-none text-gray-400"
        className="h-5 w-5 flex-none text-swamp-green-400"
        aria-hidden="true"
      />
    </Popover.Button>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel
        className={`absolute ${isAlignRight ? "-right-28" : "-left-8"} top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5`}
      >
        <div className="p-4">
          {items.map((item) => (
            <div
              key={item.name}
              className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-swamp-green-100"
            >
              {item.icon && (
                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-swamp-green-100 group-hover:bg-white">
                  <item.icon
                    className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
              )}
              <div className="flex-auto">
                <Link
                  href={item.href}
                  className="block font-semibold text-gray-900"
                >
                  {item.name}
                  <span className="absolute inset-0" />
                </Link>
                <p className="mt-1 text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        {callToActions.length > 0 && (
          <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
            {callToActions.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
              >
                {item.icon && (
                  <item.icon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
            ))}
          </div>
        )}
      </Popover.Panel>
    </Transition>
  </Popover>
);

type MobileDisclosureProps = {
  title: string;
  items: Item[];
  callToActions: Item[];
};

export const MobileDisclosure = ({
  title,
  items,
  callToActions,
}: MobileDisclosureProps) => (
  <Disclosure as="div" className="-mx-3">
    {({ open }) => (
      <>
        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-swamp-green-50">
          {title}
          <ChevronDownIcon
            className={classNames(
              open ? "rotate-180" : "",
              "h-5 w-5 flex-none"
            )}
            aria-hidden="true"
          />
        </Disclosure.Button>
        <Disclosure.Panel className="mt-2 space-y-2">
          {[...items, ...callToActions].map((item) => (
            <Link
              key={item.name}
              href={item.href}
              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-swamp-green-50"
            >
              {item.name}
            </Link>
          ))}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    // <header className="bg-white">
    <header className="bg-swamp-green-700">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Hauptmenü"
      >
        <div className="flex lg:flex-1">
          <Link href={Links.startseite} className="-m-1.5 p-1.5">
            <span className="sr-only">Schweizerdeutsch Übersetzer App</span>
            <StaticImage
              className="rounded-full"
              src="../../../images/icon.png"
              alt="Milky von der Schweizerdeutsch Übersetzer App"
              height={50}
              layout="fixed"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Hauptmenü öffnen</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <DesktopPopover
            title="Funktionen"
            items={functions}
            callToActions={[]}
          />
          <Link
            // className="text-sm font-semibold leading-6 text-gray-900"
            className="text-sm font-semibold leading-6 text-white"
            href={Links.business.index}
          >
            Angebote für Firmen
          </Link>
          <Link
            // className="text-sm font-semibold leading-6 text-gray-900"
            className="text-sm font-semibold leading-6 text-white"
            href={Links.pricing}
          >
            Preise
          </Link>
          <DesktopPopover
            title="Ressourcen"
            items={resources}
            callToActions={[]}
            isAlignRight={true}
          />
          <Link
            // className="text-sm font-semibold leading-6 text-gray-900"
            className="text-sm font-semibold leading-6 text-white"
            href={Links.kontakt}
          >
            Kontakt
          </Link>
        </Popover.Group>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>
        </div> */}
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div> */}
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <div></div>
            {/* <Link href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Schweizerdeutsch Übersetzer App</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </Link> */}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Hauptmenü schliessen</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <MobileDisclosure
                  title="Funktionen"
                  items={functions}
                  callToActions={[]}
                />
                <Link
                  href={Links.business.index}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-swamp-green-50"
                >
                  Angebote für Firmen
                </Link>
                <Link
                  href={Links.pricing}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-swamp-green-50"
                >
                  Preise
                </Link>
                <MobileDisclosure
                  title="Ressourcen"
                  items={resources}
                  callToActions={[]}
                />
                <Link
                  href={Links.kontakt}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-swamp-green-50"
                >
                  Kontakt
                </Link>
              </div>
              {/* <div className="py-6">
                <Link
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </Link>
              </div> */}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
