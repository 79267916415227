import React from "react";
import { useFormMailer } from "@flogy/use-form-mailer";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "../hooks/use-location";
import { ContentPageLayout } from "../components/ContentPageLayout";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { HeadProps } from "gatsby";
import { Links } from "../links";
import { DefaultHead } from "../components/DefaultHead";

export type OfferType = "individual-formatter" | "other";

export const offerTypeParamKey = "offerType";

type FormInputs = {
  name: string;
  company: string;
  email: string;
  offerType: OfferType;
  description: string;
  isDataPrivacyAccepted: boolean;
};

const OffertePage = () => {
  const { submit } = useFormMailer(process.env.GATSBY_FORM_MAILER_API_KEY!);

  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const currentLocation = useLocation();
  const params =
    currentLocation?.location?.href &&
    new URL(currentLocation.location.href).searchParams;
  const preselectedOfferType = params
    ? (params.get(offerTypeParamKey) as OfferType)
    : undefined;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    defaultValues: {
      offerType: preselectedOfferType,
    },
  });

  const selectedOfferType = watch(offerTypeParamKey);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    (async () => {
      try {
        setSubmitting(true);
        await submit(data.email, [
          { Thema: "Offertanfrage" },
          { Name: data.name },
          { Firma: data.company },
          { "E-Mail": data.email },
          { "Art der Offerte": selectedOfferType },
          { Beschreibung: data.description },
        ]);
        alert("Die Offertanfrage wurde erfolgreich übermittelt. Vielen Dank!");
        reset();
      } finally {
        setSubmitting(false);
      }
    })().catch(console.error);
  };

  const descriptionPlaceholderText =
    selectedOfferType === "individual-formatter"
      ? "Wie läuft der zu digitalisierende Prozess ab? Was soll bei der Formatvorlage speziell beachtet werden?"
      : "Welches Problem möchten Sie gerne mit der Schweizerdeutsch Übersetzer App lösen? ";

  return (
    <ContentPageLayout>
      <div className="mx-auto max-w-4xl px-6 pt-8 pb-16 sm:px-6 sm:py-28 lg:px-8">
        <div className="mx-auto">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Offerte anfragen
          </h1>

          <p className="mt-6 sm:mt-12">
            Gerne setze ich basierend auf der übermittelten Anfrage so schnell
            wie möglich eine passgenaue Offerte auf. Bei komplexeren
            Anforderungen oder Rückfragen würde ich mich per Mail oder
            allenfalls einem kurzen Video- oder Telefon-Meeting rückmelden.
            Vielen Dank!
          </p>

          <form
            className="mt-6 sm:mt-12 flex flex-col gap-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-2">
              <label className="block text-gray-700">Name</label>
              <input {...register("name")} type="text" required={true} />
            </div>

            <div className="flex flex-col gap-2">
              <label className="block text-gray-700">Firma</label>
              <input {...register("company")} type="text" />
            </div>

            <div className="flex flex-col gap-2">
              <label className="block text-gray-700">E-Mail Adresse</label>
              <input
                {...register("email")}
                type="email"
                placeholder="muster@email.com"
                required={true}
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="block text-gray-700">Betreff der Offerte</label>
              <select {...register("offerType")} required={true}>
                <option value="" disabled={true}>
                  Bitte wählen...
                </option>
                <option value="individual-formatter">
                  Individuelle Formatvorlage
                </option>
                <option value="other">Sonstiges</option>
              </select>
            </div>

            <div className="flex flex-col gap-2">
              <label className="block text-gray-700">Beschreibung</label>
              <textarea
                rows={5}
                {...register("description")}
                placeholder={descriptionPlaceholderText}
                required={true}
              />
            </div>

            <label className="flex flex-row items-center gap-5 text-gray-700">
              <input
                {...register("isDataPrivacyAccepted")}
                type="checkbox"
                required={true}
              />
              <span>
                Ich bin einverstanden damit, dass meine Daten zur Bearbeitung
                meines Anliegens gemäss der{" "}
                <Link href={Links.datenschutz} target="_blank">
                  Datenschutzerklärung
                </Link>{" "}
                verwendet werden.
              </span>
            </label>

            <button
              className="mt-5 bg-thunderbird-700 text-white py-3 px-8 hover:bg-thunderbird-600"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Wird gesendet..." : "Absenden"}
            </button>
          </form>
        </div>
      </div>
    </ContentPageLayout>
  );
};

export const Head = (props: HeadProps) => (
  <DefaultHead
    {...props}
    title="Offerte einholen | Schweizerdeutsch Übersetzer App"
    description="Optimiere deine Geschäftsprozesse mit der Transkriptions-App für Schweizerdeutsch und andere deutschsprachige Dialekte. Jetzt Offerte bestellen!"
  />
);

export default OffertePage;
