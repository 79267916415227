import React from "react";
import { HeadProps } from "gatsby";

import Icon from "../images/icon.png";

interface Props extends HeadProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

export const DefaultHead = ({
  location,
  children,
  title: overriddenTitle,
  description: overriddenDescription,
}: Props) => {
  const title =
    overriddenTitle ||
    "Schweizerdeutsch Übersetzer App - von Dialekt auf Deutsch";
  const description =
    overriddenDescription ||
    "Lasse gesprochenes Schweizerdeutsch auf Deutsch übersetzen (Audio zu Text). Perfekt für Interviews, Sitzungsprotokolle, Sprachnachrichten, Transkriptionen oder um Texte zu diktieren. Jetzt kostenlos ausprobieren!";
  const path = `https://schweizerdeutsch-uebersetzen.ch${location.pathname}`;
  const image = `https://schweizerdeutsch-uebersetzen.ch${Icon}`;

  return (
    <>
      <html id="html" lang="de" />
      <title id="title">{title}</title>
      <meta id="description" name="description" content={description} />
      <link id="canonical" rel="canonical" href={path} />
      <meta id="og:title" property="og:title" content={title} />
      <meta
        id="og:description"
        property="og:description"
        content={description}
      />
      <meta id="og:type" property="og:type" content="website" />
      <meta id="og:url" property="og:url" content={path} />
      <meta id="og:locale" property="og:locale" content="de_DE" />
      <meta
        id="og:site_name"
        property="og:site_name"
        content="Schweizerdeutsch übersetzen"
      />
      <meta id="og:image" property="og:image" content={image} />
      <meta
        id="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta id="twitter:title" name="twitter:title" content={title} />
      <meta
        id="twitter:description"
        name="twitter:description"
        content={description}
      />
      <meta id="twitter:image" name="twitter:image" content={image} />
      {children}
    </>
  );
};
