import React from "react";
import { Header, Footer } from "@flogy/gatsby-theme-fgs-layout";

interface Props {
  children?: React.ReactNode;
}

export const ContentPageLayout: React.FC<Props> = ({ children }) => (
  <div className="min-h-screen flex flex-col bg-gray-50">
    <Header />
    <main className="grow">{children}</main>
    <Footer />
  </div>
);
