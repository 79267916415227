import { OfferType, offerTypeParamKey } from "./pages/offerte";

export const Links = {
  startseite: "/",
  impressum: "/impressum/",
  datenschutz: "/datenschutz/",
  beispiele: {
    index: "/beispiele/",
    sitzung: "/beispiele/#sitzung",
    interview: "/beispiele/#interview",
    predigt: "/beispiele/#predigt",
  },
  videoanleitungen: "/videoanleitungen/",
  faq: "/faq/",
  kontakt: "/kontakt/",
  offer: (offerType?: OfferType) =>
    offerType ? `/offerte/?${offerTypeParamKey}=${offerType}` : "/offerte/",
  mediaKit: "/Schweizerdeutsch_uebersetzen_Medienmitteilung.zip",
  examplePdfs: {
    sermon: "/Beispiel_Transkription_Predigt.pdf",
    interview: "/Beispiel_Transkription_Interview.pdf",
    meeting: "/Beispiel_Transkription_Sitzung.pdf",
    anamnese: "/Beispiel_Transkription_Anamnese_kommentiert.pdf",
  },
  appDownloadGooglePlay:
    "https://play.google.com/store/apps/details?id=ch.floriangyger.swisstranscribe",
  appDownloadAppStore:
    "https://apps.apple.com/ch/app/schweizerdeutsch-%C3%BCbersetzen/id6446903220",
  linkedin: "https://www.linkedin.com/showcase/schweizerdeutsch-uebersetzen/",
  instagram: "https://www.instagram.com/schweizerdeutsch_uebersetzen/",
  facebook: "https://facebook.com/schweizerdeutsch.uebersetzen/",
  landing: {
    meeting: "/landing/sitzungsprotokoll-automatisch-erstellen/",
    interview: "/landing/interview-transkribieren-software-schweizerdeutsch/",
    sermon: "/landing/gottesdienst-transkribieren-mundart/",
    dictate: "/landing/diktierfunktion-fuer-mundart-dialekte-handy/",
    voiceMessage: "/landing/mundart-sprachnachricht-in-text-uebersetzen/",
    spelling: "/landing/rechtschreibung-dank-diktierfunktion/",
    learnSwissGerman: "/landing/schweizerdeutsch-verstehen-lernen/",
    videoSubtitles: "/landing/mundart-video-untertitel-erstellen/",
  },
  interview: {
    fragen: "/interview/fragen-fuer-dein-interview/",
    leitfaden: "/interview/leitfaden-fuer-erfolgreiches-interview/",
  },
  business: {
    index: "/angebote-fuer-unternehmen/",
    individualFormatters:
      "/angebote-fuer-unternehmen/individuelle-formatvorlagen/",
  },
  pricing: "/preise/",
};
